import React from "react";
import SlideOne from "../components/SlideOne/SlideOne";
import '../styles/global.css';
export default function Home() {
  return (
  <div>
    <SlideOne  />
  </div>
  )
}
