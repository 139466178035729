import React, { useState } from "react"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import Hero from "../../assets/images/hero.png"
import scribble from "../../assets/images/scribble.png"
import squiggle from "../../assets/images/squiggle.png"
import ghci from "../../assets/images/gallery/ghci.png"
import google from "../../assets/images/gallery/google.jpg"
import iwd from "../../assets/images/gallery/iwd.jpg"
import bigo from "../../assets/images/activities/bigO.jpg"
import peer from "../../assets/images/activities/peer.png"
import day from "../../assets/images/activities/day.jpg"
import base from "../../assets/images/bloggingbase.png"
import top from "../../assets/images/Bloggingtop.png"
import azure from "../../assets/images/gallery/azure.png"
import deccan from "../../assets/images/gallery/deccan.png"
import move from "lodash-move"
import Navbar from "../Navbar/Navbar"
import "./style.css"
const blogSectionVariants = {
  start: {
    scale: 1,
  },
  stop: {
    scale: 1,
    transition: {
      staggerChildren: 1.2,
    },
  },
}
const blogVariants = {
  start: {
    scale: 0.5,
    opacity: 0,
  },
  stop: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.8,
    },
  },
  hover: {
    scale: 1.1,
  },
}
const CARD_image = [
  {i:azure,h:"Azure Community Conference",
   p:"Technical speaker at Microsoft Azconf 2021",
  a:"https://www.youtube.com/watch?v=V9D9fqF8QI4&t=3096s"},
  { i: ghci,h: "GHCI conference" },
  {  i: google,
     h: "Google Singapore headquarters",
     p:"One of the first recipients of the Google womentechmakers scholarship from Kerala, India. Among the 72 scholars selected from APAC in 2018"
  },
  { i: iwd, h: "IWD technical speaker at WTM-Kochi" },
  {
    i: bigo,
    h: "The Big Oh",
    p: "series is a peer learning session, a project I designed and organized under Pehia, a non-profit organisation which is aimed at empowering gender minorities. It encompasses sessions from competitive coding to facing coding interviews.",
  },
  {
    i:deccan,
    h:"Featured in Deccan chronicle"
  },
  {
    i: day,
    h: "Dreamers to Doyenne",
    p: "  was a project aimed at creating a women support group which included college going girls to women coming back to workplace after career break. Here they gather in weekends, talk about their dreams and brainstorm to solve their problems. ",
  },
  {
    i: peer,
    h: "Peer learninig sessions",
    p: "under Pehia was aimed at bringing girls passionate about coding together and help them learn various tech stacks using resources freely available.",
  },
]
const CARD_OFFSET = 20
const SCALE_FACTOR = 0.06
var tm, ts
export default function SlideOne() {
  const [cards, setCards] = useState(CARD_image)
  const moveToEnd = from => {
    setCards(move(cards, from, cards.length - 1))
  }
  const [[initial1, direction1], setDirection1] = useState([0, -1200])
  const [[initial2, direction2], setDirection2] = useState([0, -1200])
  const [[initial3, direction3], setDirection3] = useState([0, -1200])
  const [slideOneChange, setSlideOneChange] = useState(false)
  const [slideTwo, setslideTwo] = useState(false)
  const [slideTwoChange, setSlideTwoChange] = useState(false)
  const [slideThreeChange, setSlideThreeChange] = useState(false)
  const [slidethree, setSlidethree] = useState(false)
  const [navcolor, setNavColor] = useState("black")
  const touchstart = e => {
    ts = parseInt(e.changedTouches[0].clientY)
  }
  const touchmove = (e, n) => {
    e.preventDefault()
    console.log(e.target.classList[0])
    tm = parseInt(e.changedTouches[0].clientY)
    const delta = tm - ts
    if (delta < 0) {
      if (n === 1) {
        setSlideOneChange(true)
        setNavColor("white")
        setslideTwo(true)
        setDirection1([0, -1200])
      } else if (n === 2) {
        setSlideTwoChange(true)
        setNavColor("black")
        setDirection2([0, -1200])
      }
    } else if (delta > 0) {
      if (n === 2) {
        setSlideOneChange(true)
        setSlideTwoChange(false)
        setNavColor("black")
        setDirection1([-1200, 0])
      } else if (n === 3) {
        if (e.target.classList[0] === "section-slide-three") {
          console.log(e.target.classList[0])
          setSlideTwoChange(true)
          setNavColor("white")
          setDirection2([-1200, 0])
        }
      }
      // setSlideOneChange(true)
    }
  }
  const handleWheel = (e, n) => {
    console.log(e.deltaY)
    if (e.deltaY > 0) {
      if (n === 1) {
        setSlideOneChange(true)
        setNavColor("white")
        setslideTwo(true)
        setDirection1([0, -1200])
      } else if (n === 2) {
        setSlideTwoChange(true)
        setNavColor("black")
        setDirection2([0, -1200])
      }
    } else if (e.deltaY < 0) {
      if (n === 2) {
        setSlideOneChange(true)
        setSlideTwoChange(false)
        setNavColor("black")
        setDirection1([-1200, 0])
      } else if (n === 3) {
        setSlideTwoChange(true)
        setNavColor("white")
        setDirection2([-1200, 0])
      }
      // setSlideOneChange(true)
    }
  }
  return (
    <>
      <Navbar color={navcolor} />
      <div className="slideOne">
        <motion.div
          className="hero-section"
          initial={{ y: initial1 }}
          animate={{
            y: slideOneChange ? direction1 : 0,
            transition: {
              ease: "easeInOut",
            },
          }}
          onWheel={e => {
            handleWheel(e, 1)
          }}
          onTouchStart={e => {
            touchstart(e)
          }}
          onTouchMove={e => {
            touchmove(e, 1)
          }}
        >
          <div className="ga">
            <h1 className="bold">
              <span className="anusree">Hola! I'm</span> Anusree
            </h1>
            <h3 className="bold text-center">Director pehia Foundation, ML engineer</h3>
          </div>
          <div className="hero-image-div">
            <div className="hero-image-container">
              <div className="scribble">
                {" "}
                <img src={scribble} alt="scribble" />
              </div>
              <div>
                {" "}
                <img src={Hero} alt="hero image" />
              </div>
              <div className="squiggle">
                {" "}
                <img src={squiggle} alt="squiggle" />
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <div className="slideTwo">
        <motion.div
          initial={{ y: initial2 }}
          animate={{
            y: slideTwoChange ? direction2 : 0,
            transition: {
              ease: "easeInOut",
            },
          }}
          onWheel={e => {
            handleWheel(e, 2)
          }}
          onTouchStart={e => {
            touchstart(e)
          }}
          onTouchMove={e => {
            touchmove(e, 2)
          }}
          className="section-slide-two"
        >
          <div className="slide2-section1">
            <div className="section-slide-two-heading">
              <h1 className="bold">Read my blogs ...</h1>
            </div>
            <div className="section-slide-two-button">
              <Link to="/blogs">
                <button>Read now</button>
              </Link>
            </div>
          </div>
          {slideTwo && (
            <div className="slide2-section2">
              <motion.div
                className="top-blog-section"
                variants={blogSectionVariants}
                initial="start"
                animate="stop"
              >
                <div className="blog-card base">
                  <motion.img src={base} variants={blogVariants} />
                </div>
                <div className="blog-card top">
                  <motion.img src={top} variants={blogVariants} />
                </div>
              </motion.div>
            </div>
          )}
        </motion.div>
      </div>
      <div className="slidethree">
        <motion.div
          initial={{ y: initial3 }}
          animate={{
            y: slideThreeChange ? direction3 : 0,
            transition: {
              ease: "easeInOut",
            },
          }}
          className="section-slide-three"
          onWheel={e => handleWheel(e, 3)}
          onTouchStart={e => {
            touchstart(e)
          }}
          onTouchMove={e => {
            touchmove(e, 3)
          }}
        >
          <ul className="slide3-section1">
            {cards.map((color, index) => {
              const canDrag = index === 0
              return (
                <motion.li
                  className="cardStyle"
                  key={color.i}
                  animate={{
                    top: index * -CARD_OFFSET,
                    scale: 1 - index * SCALE_FACTOR,
                    zIndex: CARD_image.length - index,
                  }}
                  drag={canDrag ? "y" : false}
                  dragConstraints={{
                    top: 0,
                    bottom: 0,
                  }}
                  onDragEnd={() => moveToEnd(index)}
                >
                  <img src={color.i} />
                  <div className="stack-heading">
                    <h2 className="bold">{color.h}</h2>
                    <div className="stack-para thin">
                    {color.p} 
                    {
                      color.a &&(
                        <a href={color.a} className="text-white">&nbsp;&nbsp;[click here]</a>
                      )
                    }
                  </div>
                  </div>
                  
                </motion.li>
              )
            })}
          </ul>
        </motion.div>
      </div>
    </>
  )
}
